import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ClientTrackLogo from '../Shared/images/client-track-logo.svg';

interface ViewInviteParams {
    id: string
}

const ViewInvitation = () => {
    const [params, setParams] = useSearchParams();
    const [inviteId, setInviteId] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const paramId = params?.get("id") || '';
        setInviteId(paramId);


    }, [params])
    
    return (
        <div className="content-page">
            <div className="container-fluid h-100">
                <div className="row align-self-center justify-content-center h-100">
                    <div className="col-sm-12 col-lg-4 text-center align-self-center">
                        <p>You have been invited to connect with: </p>
                        <h2 className="mb-4">My Sample Organization</h2>
                        <img src={ClientTrackLogo} className="img-fluid" alt="logo" />
                        {/* <p className="mb-5 mt-5">Please review your details for the connection below</p> */}
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-4">
            <div className="mb-4">
                <a href="#" className="btn btn-primary  mr-3" data-target=".meeting-modal" data-toggle="modal" onClick={(e) => navigate('/main/chat/1')}>Chat Now</a>
                <a href="chat-page.html" className="btn btn-light">Decline</a>
            </div>
            </div>
        </div>
    )
}

export default ViewInvitation;