import { Outlet } from "react-router-dom";
import Loading from "./Components/Loading";
import { SignalRContext, useSignalR } from "../Hooks/UseSignalR";

const SignalRContextRoute = () => {
    const signalRConnection = useSignalR();

    return <SignalRContext.Provider value={signalRConnection}>
    {
      signalRConnection.IsLoading ?
        <Loading /> :
        <Outlet />
    }
    </SignalRContext.Provider>
}

export default SignalRContextRoute;