import '../../backend.css';

import React, { FunctionComponent, useContext } from 'react';

import { Link } from 'react-router-dom';
import { SignOutButton } from '../../Authentication/Components/SignOutButton';
import Skippy from '../images/Skippy.jpg'
import { UserProfileContext } from '../../Hooks/useProfile';

interface TopNavProps {
    setMenuVisible: React.Dispatch<boolean>
}
const TopNav: FunctionComponent<TopNavProps> = (props) => {
    const { setMenuVisible } = props;
    const userProfile = useContext(UserProfileContext);
    

return (
    <div className="iq-top-navbar">
          <div className="iq-navbar-custom">
              <nav className="navbar navbar-expand-lg navbar-light p-0">                
                  <div className="d-flex align-items-center w-100">
                      <ul className="navbar-nav ml-auto navbar-list align-items-center justify-content-center">
                        <li className="nav-item">
                            <div id="mobile-hamburger" className="iq-navbar-logo d-flex align-items-center justify-content-between">
                                <i className="ri-menu-line wrapper-menu" onClick={e => setMenuVisible(true) }></i>
                                <a href="../backend/index.html" className="header-logo">
                                </a>
                            </div>
                        </li>
                          <li className="nav-item nav-icon dropdown caption-content">
                              <a href="#" className="search-toggle dropdown-toggle" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <img src={Skippy} className="img-fluid rounded" alt="user" />
                              </a>
                              <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                  <div className="card shadow-none m-0">
                                      <div className="card-body p-0 text-center">
                                          <div className="media-body profile-detail text-center">
                                              <img src="/assets/images/page-img/profile-bg.png" alt="profile-bg" className="profile-bg img-fluid mb-2" />
                                          </div>
                                          <div className="p-2">
                                              <h5 className="mb-1">{userProfile?.AppUser?.entity?.name}</h5>
                                              <div className="d-flex align-items-center justify-content-center mt-3">
                                                  <Link to="/main/user/profile" className="btn border mr-2">Profile</Link>
                                                  <SignOutButton />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </div>
      </div>
    );
};

export default TopNav;
