import '../backend.css';

import { NotificationPreferences, NotificationSubscriptionRequest } from '../Shared/Models/NotificationPreferences';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ApiHelper from '../Shared/ApiHelper';
import ContactInformationCard from './ContactInformationCard';
import EccoviaLogo from '../Shared/images/eccovia-logo-transparent.png';
import { ToastConfig } from '../Shared/Models/ToastConfig';
import { UserProfileContext } from '../Hooks/useProfile';
import { useMsal } from '@azure/msal-react';
import useTooltip, { InitTooltipAttributes, TooltipAttributes } from '../Hooks/useTooltip';

const UserProfile: FunctionComponent = () => {
    const [subscriptionUpdateRequest, setSubscriptionUpdateRequest] = useState<NotificationSubscriptionRequest>({} as NotificationSubscriptionRequest);
    const [userNotifications, setUserNotifications] = useState<NotificationPreferences>({} as NotificationPreferences);
    const [dirtyForm, setDirtyForm] = useState<boolean>(false);
    const [ notificationEmailVerified, setNotificationEmailVerified ] = useState<boolean>(false);
    const user = useContext(UserProfileContext);
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();
    const [emailToolTipAttributes, setEmailToolTipAttributes] = useState<TooltipAttributes | undefined>();
    
    useTooltip([userNotifications]);

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_CLIENTEX_APIBASE}/user/preferences`,
            "GET",
            undefined
        ).then(async (result: Response) => {
            var notificationPreferences: NotificationPreferences = await result.json();
            console.log("Notification preferences", notificationPreferences)
            setUserNotifications(notificationPreferences);
            setSubscriptionUpdateRequest({ 
                ...subscriptionUpdateRequest, 
                email: (notificationPreferences?.email?.at(0)?.notificationsEnabled || false),
                subscriptions: notificationPreferences?.notificationSubscriptions
            });
            let hasverifiedEmail = notificationPreferences?.email?.at(0)?.verified || false;
            setNotificationEmailVerified(hasverifiedEmail)
            setEmailToolTipAttributes(
                hasverifiedEmail
                ? undefined
                : InitTooltipAttributes("Your email must be verified before you can enable notifications.")
            );
        })
    }, [api, instance])

    useEffect(() => {
        if (dirtyForm) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/user/subscriptionpreferences`,
                "PUT",
                JSON.stringify(subscriptionUpdateRequest)
            ).then(async (result: Response) => {
                if( result.status === 200 ) {
                    var notificationPreferences: NotificationPreferences = await result.json();
                    console.log("Notification preferences", notificationPreferences)
                    setUserNotifications(notificationPreferences);
                    setSubscriptionUpdateRequest({ 
                        ...subscriptionUpdateRequest, 
                        email: (notificationPreferences?.email?.at(0)?.notificationsEnabled || false),
                        subscriptions: notificationPreferences?.notificationSubscriptions
                    });
                    setDirtyForm(false);
                    toast.success("Successfully saved changes.", ToastConfig)
                } else {
                    toast.error(await result.json());
                }
            })
        }
        
    }, [dirtyForm, api, instance])

return (
    <>
        <div className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="card car-transparent" style={{ background: "#fcfcfc"}}>
                            <div className="card-body p-0" >
                                <div className="profile-image position-relative d-flex justify-content-center">
                                    <img src={EccoviaLogo} className="img-fluid rounded" alt="profile" />
                                </div>
                                <div className="profile-overly">
                                    <h3>{user.AppUser?.entity.name}</h3>
                                    <span>User</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContactInformationCard userNotifications={userNotifications} />
                </div>
                <hr />
                <div className="row">     
                    <div className="col-md-6 col-lg-6">
                        <div className="card card-block card-stretch card-height">
                            <div className="card-header">
                                <div className="header-title">
                                    <h4 className="card-title">Notifications</h4>
                                </div>
                            </div>
                            <div className="card-body d-flex justify-content-between align-items-center flex-wrap">
                                <div className="">
                                    <h5 className="mb-1">Email Notifications</h5>
                                    <p className="mb-0">Enable email notifications to your primary email.</p>
                                </div>
                                <div className="d-flex mt-2 mt-md-0">
                                    <button type="button" className={`btn btn-outline-primary mr-3 ${userNotifications?.email?.at(0)?.notificationsEnabled ? "active" : ""}`}
                                        disabled={!notificationEmailVerified}
                                        {...emailToolTipAttributes}
                                        onClick={e =>
                                        {
                                            setSubscriptionUpdateRequest({ ...subscriptionUpdateRequest, email: true});
                                            setDirtyForm(true);
                                        }
                                    }>Enable</button>
                                    <button type="button" className={`btn btn-outline-primary ${userNotifications?.email?.at(0)?.notificationsEnabled ? "" : "active"}`}
                                        disabled={!notificationEmailVerified}
                                        {...emailToolTipAttributes}
                                        onClick={e =>
                                        {
                                            setSubscriptionUpdateRequest({ ...subscriptionUpdateRequest, email: false});
                                            setDirtyForm(true);
                                        }}>Disable</button>
                                </div>
                            </div>
                            {/* <div className="card-body d-flex justify-content-between align-items-center flex-wrap">
                                <div className="">
                                    <h5 className="mb-1">SMS Notifications</h5>
                                    <p className="mb-0">Enable text message notifications to your primary phone number.</p>
                                </div>
                                <div className="d-flex mt-2 mt-md-0">
                                    <button type="button" className={`btn btn-outline-primary mr-3 ${userNotifications?.phone?.at(0)?.notificationsEnabled ? "active" : ""}`} onClick={e =>
                                        {
                                            setSubscriptionUpdateRequest({ ...subscriptionUpdateRequest, sms: true});
                                            setDirtyForm(true);
                                        }}>Enable</button>
                                    <button type="button" className={`btn btn-outline-primary ${userNotifications?.phone?.at(0)?.notificationsEnabled ? "" : "active"}`} onClick={e =>
                                        {
                                            setSubscriptionUpdateRequest({ ...subscriptionUpdateRequest, sms: false});
                                            setDirtyForm(true);
                                        }}>Disable</button>
                                </div>  
                            </div> */}
                            <div className="card-body d-flex justify-content-between align-items-center flex-wrap">
                                <div className="">
                                    <h5 className="mb-1">Events</h5>
                                    <p className="mb-0">Toggle notification events</p>
                                </div>
                                <div className="d-flex mt-3 mt-md-0 w-100 justify-content-between">
                                    <label className="m-r-1 text-gray">Chat Message</label>
                                    <div>
                                        <button 
                                            type="button" 
                                            className={`btn btn-outline-primary mr-1 ${
                                                userNotifications?.notificationSubscriptions?.chatMessage || userNotifications.notificationSubscriptions?.chatMessage === undefined ? "active" : ""}`}
                                            onClick={e => {
                                                setSubscriptionUpdateRequest({ ...subscriptionUpdateRequest, subscriptions: { ...subscriptionUpdateRequest.subscriptions, chatMessage: !subscriptionUpdateRequest.subscriptions.chatMessage}});
                                                setDirtyForm(true);
                                            }}>{userNotifications?.notificationSubscriptions?.chatMessage ? "ON" : "OFF"}</button>
                                    </div>
                                </div>  
                                <div className="d-flex mt-3 mt-md-0 w-100 justify-content-between">
                                    <label className="m-r-1 text-gray" >New Connections</label>
                                    <div className="">
                                        <button 
                                            type="button" 
                                            className={`btn btn-outline-primary mr-1 ${
                                                userNotifications?.notificationSubscriptions?.connectionRequests || userNotifications.notificationSubscriptions?.connectionRequests === undefined ? "active" : ""}`}
                                            onClick={e => {
                                                setSubscriptionUpdateRequest({ ...subscriptionUpdateRequest, subscriptions: { ...subscriptionUpdateRequest.subscriptions, connectionRequests: !subscriptionUpdateRequest.subscriptions.connectionRequests}});
                                                setDirtyForm(true);
                                            }}                                            
                                        >
                                                {userNotifications?.notificationSubscriptions?.connectionRequests ? "ON" : "OFF"}
                                        </button>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default UserProfile;
