import { useEffect } from "react";

const addToolTip = (jQuery: any) => {
    jQuery('[data-toggle="tooltip"]').tooltip();
}

/**
 * Adds a tooltip to a component. This happens AFTER component has rendered.
 * Component must have data-toggle="tooltip" data-placement="{top|bottom|left|right}" data-original-title="{Tooltip content}"
 * See https://templates.iqonic.design/skycall/html/backend/ui-tooltips.html for more options.
 * @param dependencies A list of components to watch.
 * If present, effect will only activate if the values in the list change.
 */
const useTooltip = (dependencies?: any[]) => {
    useEffect(() => {
        const w = window as any;
        if(w.jQuery)
            addToolTip(w.jQuery);
        else
            console.log("Tooltip could not be added because JQuery could not be found.");
    // eslint-disable-next-line
    }, dependencies);
};

export default useTooltip;

export interface TooltipAttributes {
    "data-toggle": string,
    "data-placement": string,
    "data-original-title": string
}

export const InitTooltipAttributes = (title: string, placement: 'top' | 'bottom' | 'left' | 'right' | undefined = undefined): TooltipAttributes => {
    return {
        "data-toggle": "tooltip",
        "data-placement": placement ?? "top",
        "data-original-title": title
    }
}