import { createContext, useCallback, useEffect, useState } from 'react';

import ApiHelper from '../Shared/ApiHelper';
import { ApplicationUser } from '../Shared/Models/ApplicationUser';
import { UserProfile } from '../Shared/Models/UserProfile';
import { useMsal } from '@azure/msal-react';

export const UserProfileContext = createContext<UserProfile>({} as UserProfile);

export function useProfile(): UserProfile {

    const [ api ] = useState(new ApiHelper());
    const { instance, accounts } = useMsal();
    const [ user, setUser ] = useState<ApplicationUser>();
    const [ chatNavOpen, setChatNavOpen ] = useState(false);
    const isLoggedIn: boolean = accounts.length > 0 && !!user;
    const [ isLoading, setIsLoading] = useState<boolean>(false);

    const initializeUserProfile = useCallback(() => {
        if (accounts.length > 0 && !user) {
            setIsLoading(true);
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/user/me`,
                "GET",
                undefined
            ).then(async (result: Response) => {
                console.log("result", result);
                var appUser: ApplicationUser = await result.json();
                setUser({ ...user ?? {}, ...appUser });
            })
            .catch((reason) => console.log("initialize profile rejected", reason))
            .finally(() => setIsLoading(false));
        }
    }, [accounts, api, instance, user]);

    useEffect(() => {
        initializeUserProfile();
    }, [accounts, api, initializeUserProfile, instance, user]);

    return {
        AppUser: user,
        Account: accounts?.at(0),
        IsLoggedIn: isLoggedIn,
        ChatNavOpen: chatNavOpen,
        SetChatNavOpen: setChatNavOpen,
        CanAccessApp: user === undefined || user.entity?.registeredApps?.find((appId: string) => appId === process.env.REACT_APP_B2C_CLIENTID) !== undefined,
        IsLoading: isLoading,
        RefreshUser: () => setUser(undefined)
    };
}

