import { FunctionComponent } from "react";

export interface LoadingProps {
    Size?: LoadingSize
}

export enum LoadingSize {
    Normal,
    Small
}

const Loading: FunctionComponent<LoadingProps> = ({Size = LoadingSize.Normal}) => {
    let size = "";
    switch(Size) {
        case LoadingSize.Small:
            size = "spinner-border-sm";
            break;
    }
    return (
        <div className={`spinner-border ${size}`} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default Loading;