import { FunctionComponent, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EmailVerification from "../Shared/Components/EmailVerification";
import { ToastConfig } from "../Shared/Models/ToastConfig";
import { NotificationPreferences } from "../Shared/Models/NotificationPreferences";
import ApiHelper from "../Shared/ApiHelper";
import { useMsal } from "@azure/msal-react";
import PhoneVerification from "../Shared/Components/PhoneVerifcation";

export interface ContactPageProps {
    type: string,
    notificationPreferences: NotificationPreferences,
    isUpdate: boolean
}

const ContactPage : FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());
    const [ type, setType ] = useState<string>('email');
    const [ notificationPreferences, setnotificationPreferences ] = useState<NotificationPreferences>({} as NotificationPreferences);
    const [ isUpdate, setIsUpdate ] = useState<boolean>(true);

    useEffect(() => {
        let props = location.state as ContactPageProps | undefined;
        if(props) {
            setType(props.type);
            setnotificationPreferences(props.notificationPreferences);
            setIsUpdate(props.isUpdate);
        } else {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/user/preferences`,
                "GET"
            ).then(async (result: Response) => {
                let notificationPreferences: NotificationPreferences = await result.json();
                setnotificationPreferences(notificationPreferences);
            })
        }
    }, [location.state, api, instance]);

    const updateEmailValue = (value: string) => {
        let preferences = notificationPreferences;
        let email = preferences.email.find(x => x);
        if(email) {
            email.email = value;
            setnotificationPreferences(preferences);
        }
    }

    const onConfirmationSuccess = () => {
        if( isUpdate ) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/user/preferences`,
                "PUT",
                JSON.stringify(notificationPreferences)
            ).then(async (result: Response) => {
                if(result.ok) {
                    navigate("/main/user/profile");
                    toast.success(`Your ${type} has been successfully updated.`, ToastConfig);
                } else {
                    toast.error('Failed to update. Please try again later.', ToastConfig);
                }
            })
        }
    }
    
    return (
        <div className="container">
            <div className="row align-items-center justify-content-center height-self-center">
               <div className="col-lg-8">
               <NavLink to="/main/user/profile">
                    <i className="la la-arrow-circle-left mr-2"></i><span className="menu-text">Return to Profile</span>
                </NavLink>
                  <div className="card auth-card bg-light">
                     <div className="card-body p-0">
                        <div className="row align-items-center auth-content">
                           <div className="col-lg-7 align-self-center text-grey">
                              <div className="p-3">
                                 <h2 className="mb-2 text-center text-primary">{isUpdate ? "Update" : "Verify"} {type === "phone" ? "Phone" : "Email"}</h2>
                                 <div className="justify-content-around align-items-center mt-3">
                                    <div className="d-flex flex-column align-items-center text-edit">
                                       {
                                          type === "phone" ?
                                            <PhoneVerification
                                                value={notificationPreferences.phone?.find(x => x)?.phone ?? ''}
                                                onValueUpdate={(v: string) => {}}
                                                isUpdate={isUpdate}
                                                onSendCodeSuccess={() => toast.success(`A validation code has been sent to the requested ${type}.`, ToastConfig)}
                                                onSendCodeError={(err: string) => toast.error(err, ToastConfig)}
                                                onConfirmCodeSuccess={onConfirmationSuccess}
                                                onConfirmCodeError={(err: string) => toast.error(err, ToastConfig)} /> :
                                            <EmailVerification
                                                value={notificationPreferences.email?.find(x => x)?.email ?? ''}
                                                onValueUpdate={updateEmailValue}
                                                isUpdate={isUpdate}
                                                onSendCodeSuccess={() => toast.success(`A validation code has been sent to the requested ${type}.`, ToastConfig)}
                                                onSendCodeError={(err: string) => toast.error(err, ToastConfig)}
                                                onConfirmCodeSuccess={onConfirmationSuccess}
                                                onConfirmCodeError={(err: string) => toast.error(err, ToastConfig)} />
                                       }
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        </div>
    )
}

export default ContactPage;