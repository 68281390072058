import '../../backend.css';

import { Outlet, useNavigate } from 'react-router-dom';
import React, { FunctionComponent, useContext, useState } from 'react';

import LeftNav from './LeftNav';
import TopNav from './TopNav';

const Main: FunctionComponent = () => {
    const [menuVisible, setMenuVisible] = useState<boolean>(false);

    return  (
        <>
            <LeftNav menuVisible={menuVisible} setMenuVisible={setMenuVisible}/>
            <TopNav setMenuVisible={setMenuVisible}/>
            <Outlet />
        </>
    )
}

export default Main;