import '../../backend.css';
import './Login.css';
import '../../App.css';

import { Link, useNavigate } from 'react-router-dom';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { b2cPolicies, loginRequest } from '../AuthConfig';

import EccoviaLogo from '../../Shared/images/eccovia-logo-transparent.png';
import { RedirectRequest } from '@azure/msal-browser';
import { SignInButton } from './SignInButton';
import { UserProfileContext } from '../../Hooks/useProfile';
import { useMsal } from '@azure/msal-react';
import { SignOutButton } from './SignOutButton';

const Login: FunctionComponent = () => {
   const navigator = useNavigate();
   const { instance } = useMsal();
   const userProfile = useContext(UserProfileContext); 

   useEffect(() => {
      if (userProfile?.IsLoggedIn && userProfile?.CanAccessApp) {
         navigator("/main/chat");
      }
  }, [userProfile?.IsLoggedIn, instance, navigator]);

return (
    <div className="wrapper">
      <section className="login-content">
         <div className="container">
            <div className="row align-items-center justify-content-center height-self-center">
               <div className="col-lg-8">
                  <div className="card auth-card bg-light">
                     <div className="card-body p-0">
                        <div className="row align-items-center auth-content">
                           <div className="col-lg-7 align-self-center text-grey">
                              <div className="p-3">
                                 <h2 className="mb-2 text-center text-primary">ClientEmpower</h2>
                                 {
                                    !userProfile.CanAccessApp &&
                                    <p className="text-center text-danger">You are not authorized access to this application.</p>
                                 }
                                 <div className="row justify-content-around align-items-center mt-3">
                                    <div className="col-lg-10">
                                       {userProfile?.IsLoggedIn
                                          ? <SignOutButton />
                                          : <SignInButton />
                                       }
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-lg-5 content-right">
                              <img src={EccoviaLogo} className="img-fluid image-right" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      </div>
    );
};

export default Login;
