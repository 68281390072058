import { ChangeEvent, useEffect, useState } from "react";
import { CompletionStatus, Registration, RegistrationProvider, UpdateRegistrationResponse } from "../../Shared/Models/Registration";
import { Identifier, IdentifierType } from "../../Shared/Models/Identifier";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { b2cPolicies, loginRequest } from "../AuthConfig";

import ApiHelper from "../../Shared/ApiHelper";
import EccoviaLogo from '../../Shared/images/eccovia-logo-transparent.png';
import { ToastConfig } from "../../Shared/Models/ToastConfig";
import { useMsal } from "@azure/msal-react";
import Loading from "../../Shared/Components/Loading";
import { RedirectRequest } from "@azure/msal-browser";

const ViewRegistration = () => {
    const { instance, accounts } = useMsal();
    const [params] = useSearchParams();
    const [regId, setRegId] = useState('');
    const [registration, setRegistration] = useState<Registration>();
    const [api] = useState(new ApiHelper());
    const [isFormValid, setFormValid] = useState(false);
    const [maxValidationAttempsReached, setMaxValidationAttempsReached] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const paramId = params?.get("id") || '';
        setRegId(paramId);
    }, [params]);

    useEffect(() => {
        if (registration && registration.completionStatus !== CompletionStatus.Verified && registration.completionStatus !== CompletionStatus.Completed && accounts.length > 0) {
            instance.logoutRedirect({
                onRedirectNavigate: () => {
                    // Return false to stop navigation after local logout
                    return false;
                }
            });
            toast.success("You have been signed out of any other accounts during registration.", ToastConfig);
        }
    }, [registration, accounts, instance])

    useEffect(() => {
        if(registration && registration.completionStatus === CompletionStatus.Completed){
            navigate("/");
        }
    })

    useEffect(() => {
        if (regId) {
            api.callApiAnonymous(
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/registration/${regId}`,
                "GET",
                undefined
            ).then(res => res.json())
                .then(data => {
                    setRegistration(data);
                });
        }
    }, [regId, api])

    useEffect(() => {
        setFormValid(!!registration?.birthdate);
    }, [registration])

    const handleFormUpdate = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "emailIdentifier") {
            const emailIdentifier = { ...registration?.emailIdentifier, value: e.target.value } as Identifier;
            setRegistration({ ...registration, emailIdentifier } as Registration)
        } else {
            setRegistration({ ...registration, [e.target.name]: e.target.value } as Registration)
        }
    }

    const redirectToSignUp = () => {
        const signUpRequest: RedirectRequest = {
            authority: b2cPolicies.authorities.signUp.authority,
            scopes: loginRequest.scopes,
            redirectUri: process.env.REACT_APP_REGISTRATION_REDIRECT,
            redirectStartPage: "/linkaccount?id=" + regId
        }
        instance.loginRedirect(signUpRequest);
    };

    useEffect(() => {
        if(registration && registration.completionStatus === CompletionStatus.Verified){
            redirectToSignUp();
        }
    })

    const handleSubmit = (e: any) => {
        api.callApiAnonymous(
            `${process.env.REACT_APP_CLIENTEX_APIBASE}/registration/${regId}`,
            "PUT",
            JSON.stringify({...registration, completionStatus: CompletionStatus.Verified}),
            undefined,
            false
        ).then(async (res) => {
            if (res.ok) {
                toast.success("Registration verification submitted", ToastConfig);
                redirectToSignUp();
            } else if (res.status === 409) {
                let response = await res.json() as UpdateRegistrationResponse | undefined;
                toast.error(response?.error ?? "Registration failed", ToastConfig);
            } else {
                setMaxValidationAttempsReached(true);
                toast.error("Registration failed. Too many attempts.", ToastConfig)
            }
        })
    }

    const handleDecline = (e: any) => {
        api.callApiAnonymous(
            `${process.env.REACT_APP_CLIENTEX_APIBASE}/registration/${regId}`,
            "PUT",
            JSON.stringify({...registration, completionStatus: CompletionStatus.Declined})
        ).then(res => {
            if (res.ok) {
                toast.success("Registration declined", ToastConfig)
            }
            setRegistration({...registration, completionStatus: CompletionStatus.Declined} as Registration);
        })
    }

    const getProviderInfo = ( provider: RegistrationProvider ) : string  => {
        const identifier = provider.identifiers.find( x => x.type === IdentifierType.Email || x.type === IdentifierType.Phone )?.value;
        var providerInfo = provider.name;
        if(identifier){
            providerInfo += " at " + identifier;
        }
        return providerInfo;
    }

    return (
        <div className="wrapper">
            <section className="login-content">
                <div className="container">
                    <div className="row align-items-center justify-content-center height-self-center">
                        <div className="col-lg-8">
                            <div className="card auth-card bg-light">
                                <div className="card-body p-0">
                                    <div className="row align-items-center auth-content">
                                    {registration === undefined ? 
                                        (<div className="col-lg-7 align-self-center"><div className="text-center mt-5"><Loading /></div></div>) :
                                        (<div className="col-lg-7 align-self-center">
                                            <div className="p-3">
                                                <h2 className="mb-2 text-center text-primary">Registration</h2>
                                                {(registration.completionStatus === CompletionStatus.Pending && !maxValidationAttempsReached &&
                                                <>
                                                <p className="text-center text-grey">Confirm your details to create your account.</p>
                                                <div className="row justify-content-around align-items-center mt-3">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="col">
                                                                <label>Email</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="emailIdentifier"
                                                                    disabled={false}
                                                                    defaultValue={registration.emailIdentifier?.value}
                                                                    onChange={handleFormUpdate} />
                                                            </div>
                                                        </div>
                                                        <div className="form-row mt-2">
                                                            <div className="col">
                                                                <label>Full Name</label>
                                                                <input type="text" className="form-control" name="name" defaultValue={registration.name} onChange={handleFormUpdate} />
                                                            </div>
                                                            <div className="col">
                                                                <label>Birthday</label>
                                                                <input type="date" className="form-control" name="birthdate" onChange={handleFormUpdate} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                </>)}
                                                {registration.completionStatus === CompletionStatus.Verified && 
                                                    <p className="text-center text-grey">Thank you for confirming your account.</p> 
                                                }
                                            </div>
                                            <div className="row justify-content-center p-3">
                                                {
                                                    registration.completionStatus === CompletionStatus.Verified &&
                                                            <div className="mb-4">
                                                                <Link to="/login" className="btn btn-primary">Return to Login</Link>
                                                            </div>
                                                }
                                                {
                                                    registration.completionStatus === CompletionStatus.Pending && !maxValidationAttempsReached &&
                                                            <div className="mb-4">
                                                                <button className="btn btn-primary  mr-3" disabled={!isFormValid} onClick={handleSubmit}>Accept</button>
                                                                <button className="btn btn-light" onClick={handleDecline}>Decline</button>
                                                            </div>
                                                }
                                                {
                                                    registration.completionStatus === CompletionStatus.Declined && 
                                                            <div className="mb-4">
                                                                <p className="text-center text-grey">You may now close the browser window.</p>
                                                            </div>
                                                }
                                                {
                                                    (registration.completionStatus === CompletionStatus.Failed || maxValidationAttempsReached) &&
                                                            <div className="mb-4">
                                                                <p className="text-center text-grey">Registration failed.</p>
                                                                <p className="text-center text-grey">Please reach out to {registration.associatedProvider ? getProviderInfo(registration.associatedProvider) : "your provider"} to correct your information.</p>
                                                            </div>
                                                }
                                            </div>
                                        </div>)}
                                        <div className="col-lg-5 content-right">
                                            <img src={EccoviaLogo} className="img-fluid image-right" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ViewRegistration;