import { Outlet, useNavigate } from "react-router";
import { useContext, useEffect } from "react"

import Loading from "../Components/Loading";
import { UserProfileContext } from "../../Hooks/useProfile"
import { useMsal } from "@azure/msal-react";

const AuthRoute = () => {
    const userProfile = useContext(UserProfileContext);
    const { accounts } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        if (accounts.length === 0 && !userProfile?.IsLoggedIn)
            navigate("/login");
    }, [accounts.length, navigate, userProfile, userProfile?.IsLoggedIn]);

    return accounts.length > 0 && !!userProfile.AppUser ? 
        <Outlet /> : 
        <div className="text-center m-5">
            <Loading />
        </div>
}

export default AuthRoute;