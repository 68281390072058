import { FunctionComponent, useState } from "react";
import { CommunicationVerificationProps } from "./CommunicationVerificationProps";

const PhoneVerification: FunctionComponent<CommunicationVerificationProps> = ({value, isUpdate, onSendCodeSuccess, onSendCodeError, onConfirmCodeSuccess, onConfirmCodeError}) => {
    const [ codeWasSent, setCodeWasSent ] = useState<boolean>(false);
    const [ code, setCode ] = useState<string>('');

    const sendCode = () => {
        onSendCodeError('Phone validation has not been implemented.')
        setCodeWasSent(true);
     }
  
     const confirmCode = () => {
        onConfirmCodeError('Phone validation has not been implemented.')
     }
    
    return (
        <>
            <input type="text" className="mt-2 text-center form-control" defaultValue={value} disabled={!isUpdate} />
            <p className="text-center text-danger">By submitting this form and signing up for texts, you consent to receive text messages for notifications related to your ClientEmpower account (e.g., new secure messages, new connection requests). Consent is not a condition of purchase. Msg & data rates may apply. Msg frequency varies. Unsubscribe at any time by replying STOP or clicking the disable link on the notification preferences page.</p>
            <button className="btn-clearall a ml-1 flex-grow-2" onClick={sendCode}>Send Code</button>
            {codeWasSent &&
            <>
                <input type="text" className="mt-2 text-center form-control" onChange={e => setCode(e.target.value)} />
                <button className="btn btn-primary w-100 mt-2" onClick={confirmCode} disabled={code.length === 0}>Validate</button>
            </>
            }
        </>
    )
}

export default PhoneVerification;