import { useContext, useEffect, useState } from "react";
import { CompletionStatus, Registration } from "../../Shared/Models/Registration";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { loginRequest } from "../AuthConfig";

import ApiHelper from "../../Shared/ApiHelper";
import EccoviaLogo from '../../Shared/images/eccovia-logo-transparent.png';
import { ToastConfig } from "../../Shared/Models/ToastConfig";
import { UserProfileContext } from "../../Hooks/useProfile";
import { useMsal } from "@azure/msal-react";
import Loading from "../../Shared/Components/Loading";

const CompleteRegistration = () => {
    const { instance } = useMsal();
    const [params] = useSearchParams();
    const [regId, setRegId] = useState('');
    const [isLinked, setIsLinked] = useState<boolean>(false);
    const [registration, setRegistration] = useState<Registration>();
    const [api] = useState(new ApiHelper());
    const userProfile = useContext(UserProfileContext);
    const navigate = useNavigate();

    useEffect(() => {
        const paramId = params?.get("id") || '';
        setRegId(paramId);
    }, [params]);

    useEffect(() => {
        if(!registration || !userProfile || !navigate) {
            return;
        }
        if (registration.completionStatus === CompletionStatus.Verified && !userProfile.IsLoading && userProfile.AppUser) {
            api.callApi(
                instance,
                loginRequest.scopes,
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/entity/${registration?.entityId}/registration/${regId}`,
                "POST",
                undefined
            ).then(response => {
                if (response.ok) {
                    userProfile.RefreshUser();
                    setIsLinked(true);
                } else {
                    toast.error("Failed to create account.", ToastConfig);
                }
            })
        }
    }, [registration, instance, api, userProfile.AppUser, regId, userProfile, navigate])

    useEffect(()  => {
        if(!userProfile.IsLoading && userProfile.CanAccessApp && isLinked) {
            navigate("/");
        }
    }, [userProfile.IsLoading, userProfile.CanAccessApp, isLinked, navigate]);

    useEffect(() => {
        if(!registration) {
            return;
        }
        if (registration.completionStatus !== CompletionStatus.Verified) {
            navigate("/registration");
        }
    }, [navigate, registration])

    useEffect(() => {
        if (regId) {
            api.callApiAnonymous(
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/registration/${regId}`,
                "GET",
                undefined
            ).then(res => res.json())
                .then(data => {
                    setRegistration(data);
                });
        }
    }, [regId, api])

    return (
        <div className="wrapper">
            <section className="login-content">
                <div className="container">
                    <div className="row align-items-center justify-content-center height-self-center">
                        <div className="col-lg-8">
                            <div className="card auth-card bg-light">
                                <div className="card-body p-0">
                                    <div className="row align-items-center auth-content">
                                    {registration === undefined ? 
                                        (<div className="col-lg-7 align-self-center"><div className="text-center mt-5"><Loading /></div></div>) :
                                        (<div className="col-lg-7 align-self-center">
                                            <div className="p-3">
                                                <h2 className="mb-2 text-center text-primary">Registration</h2>
                                                {registration.completionStatus === CompletionStatus.Verified && 
                                                    <p className="text-center text-grey">Creating account...</p> 
                                                }
                                            </div>
                                        </div>)}
                                        <div className="col-lg-5 content-right">
                                            <img src={EccoviaLogo} className="img-fluid image-right" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CompleteRegistration;