import { AccountInfo, IPublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { Slide, toast } from 'react-toastify';

import { ToastConfig } from './Models/ToastConfig';
import { apiConfig } from '../Authentication/AuthConfig';

class ApiHelper {
    async callApiWithToken(token: string, endpoint: string, method: string, payload?: string, headers?: Headers): Promise<Response> {
        if (!headers) 
            headers = new Headers();
        const bearer = 'Bearer ' + token;
        headers.append('Authorization', bearer);
        headers.append(process.env.REACT_APP_CLIENTEX_APIKEYNAME || '', process.env.REACT_APP_CLIENTEX_APIKEYVALUE || '');
        headers.append("Ocp-Apim-Subscription-Key", process.env.REACT_APP_CLIENTEX_APIKEYVALUE || '');
        if (payload) {
            headers.append('Content-Type', 'application/json');
        }
        headers.append('Accept', 'application/json;application/problem+json;text/html');
        const options = {
            method: method,
            headers: headers,
            credentials: 'include' as RequestCredentials,
            ...(payload && { body: payload }),
        };

        let hasToasted = false;
        try {
            const response = await fetch(endpoint, options);
            return response;
        } catch (error) {
            if (!hasToasted) {
                if (error instanceof TypeError) {
                    toast.error(error.message, ToastConfig);
                }
            }
            throw error;
        }
    }

    async callApiAnonymous(endpoint: string, method: string, payload?: string, headers?: Headers, shouldToast: boolean = true): Promise<Response> {
        if (!headers) 
            headers = new Headers();
        headers.append(process.env.REACT_APP_CLIENTEX_APIKEYNAME || '', process.env.REACT_APP_CLIENTEX_APIKEYVALUE || '');
        if (payload) {
            headers.append('Content-Type', 'application/json');
        }
        headers.append('Accept', 'application/json;application/problem+json;text/html');
        const options = {
            method: method,
            headers: headers,
            credentials: 'include' as RequestCredentials,
            ...(payload && { body: payload }),
        };

        let hasToasted = false;
        try {
            const response = await fetch(endpoint, options);
            if (!response.ok && shouldToast) {
                hasToasted = true;
                response.json().then((data) => {

                    toast.error(data.title, ToastConfig);

                });
                throw new Error("Something went wrong");
            }
            return response;
        } catch (error) {
            if (!hasToasted && shouldToast) {
                if (error instanceof TypeError) {
                    toast.error(error.message, ToastConfig);
                }
            }
            throw error;
        }
    }

    async callApi(
        msalApp: IPublicClientApplication,
        scopes: string[],
        endpoint: string,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
        payload?: string,
        headers?: Headers,
    ): Promise<Response> {
        const account = msalApp.getAllAccounts().find((a: AccountInfo) => a.idTokenClaims?.aud === process.env.REACT_APP_B2C_CLIENTID);
        const accessTokenRequest = {
            scopes: apiConfig.scopes, //THIS IS A HARD CODED VALUE -- FOR LATER, PROBABLY REFACTOR TO PASS CORRECT SCOPES
            account: account,
        };

        try {
            const silentAuthenticationResponse = await msalApp.acquireTokenSilent(accessTokenRequest);
            return this.callApiWithToken(silentAuthenticationResponse.accessToken, endpoint, method, payload, headers);
        } catch (silentAuthenticationError) {
            msalApp.logout();
            throw silentAuthenticationError;
        }
    }
}

export default ApiHelper;
