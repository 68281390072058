import '../../backend.css';
import './LeftNav.css';

import { NavLink, useLocation } from 'react-router-dom';
import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';

import ChatNav from '../../Chat/Components/ChatNav';
import EccoviaLogo from '../images/eccovia-logo-transparent.png';
import { SignalRConnection } from '../Models/SignalRConnection';
import { SignalRContext } from '../../Hooks/UseSignalR';
import { UserProfile } from '../Models/UserProfile';
import { UserProfileContext } from '../../Hooks/useProfile';

interface LeftNavProps {
    menuVisible: boolean,
    setMenuVisible: React.Dispatch<boolean>
}
const LeftNav: FunctionComponent<LeftNavProps> = (props) => {
    const location = useLocation();
    const userProfile = useContext<UserProfile>(UserProfileContext);
    const signalRConnection = useContext<SignalRConnection>(SignalRContext);
    const { menuVisible, setMenuVisible } = props;
    const [showChatNav, setShowChatNav] = useState<boolean>();
    const chatNav = useRef<HTMLAnchorElement>(null);
    const navBar = useRef<HTMLDivElement>(null);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    
    useEffect(() => {
        setShowChatNav(location.pathname.includes("/main/chat"));
    }, [location])

    const clickChat = () => {
        chatNav.current?.click();
        setMenuVisible(false);
    }

    const toggleMenu = (openMenu: boolean) => {
        if (navBar !== null && navBar.current) {
            navBar.current.className = openMenu ? "small-saidbar nav-open" : "small-saidbar nav-closed"; 
        }
    }

    useEffect(() => {
        toggleMenu(menuVisible)
    }, [menuVisible])

    useEffect(() => {
        let unreadGroups = signalRConnection?.ChatGroups?.map(group => {
            const chatGroupUser = group.users.find(x => x.id === userProfile?.AppUser?.entity.id);
            let unreadMessages = group.messages.filter(x => !chatGroupUser?.lastRead || x.time > chatGroupUser?.lastRead);
            return unreadMessages.length;
        })
        let unreadCount = 0;
        if(unreadGroups && unreadGroups.length > 0){
            unreadCount = unreadGroups.reduce((runningTotal, next) => runningTotal + next);
        }
        setUnreadCount(unreadCount);
    }, [userProfile?.AppUser?.entity.id, signalRConnection?.ChatGroups])

return (
    <div className="small-saidbar" ref={navBar}>
          <div className="iq-sidebar-logo d-flex justify-content-between">
              <div onClick={clickChat} style={{cursor: "pointer"}}>
              <img src={EccoviaLogo} className="img-fluid" alt="logo" />
              </div>
              <div className="iq-menu-bt-sidebar">
                  <div className="iq-menu-bt align-self-center">
                      <div className="wrapper-menu open" onClick={e => setMenuVisible(false) }>
                          <div className="main-circle"><i className="ri-close-line"></i></div>
                      </div>
                  </div>
              </div>
          </div>
          <nav className="iq-sidebar-menu">
                <ul id="iq-sidebar-toggle" className="iq-menu">
                  <li className="">
                    <NavLink to="/main/chat" className={({ isActive }) => isActive ? "active" : ""} ref={chatNav} onClick={e => setMenuVisible(false) }>
                        <i className="las la la-vimeo-square la-vine la-vk la-volume-down la-volume-off la-volume-up la-warning la-wechat iq-arrow-left"></i>
                        {
                            unreadCount > 0 &&
                            <div className="badge badge-primary nav-badge">{unreadCount > 9 ? "9+" : unreadCount}</div>
                        }
                        <span className="menu-text">Chat</span>
                    </NavLink>
                  </li>
                  {/* <li className="">
                    <NavLink to="/feedback" className={({ isActive }) => isActive ? "active" : ""} title="Feedback">
                        <i className="la la-book iq-arrow-left"></i><span className="menu-text">Feedback</span>
                    </NavLink>
                  </li> */}
             </ul>
          </nav>
          { showChatNav &&
          <ChatNav />
          }
      </div>
    );
};

export default LeftNav;
