import { Outlet } from "react-router-dom";
import { UserProfileContext, useProfile } from "../Hooks/useProfile";
import Loading from "./Components/Loading";

const UserContextRoute = () => {
    const userProfile = useProfile();

    return <UserProfileContext.Provider value={userProfile}>
    {
      userProfile.IsLoading ?
        <Loading /> :
        <Outlet />
    }
    </UserProfileContext.Provider>
}

export default UserContextRoute;