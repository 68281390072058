import '../backend.css';

import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';

import { ChatGroup } from '../Shared/Models/ChatGroup';
import { ChatUser } from '../Shared/Models/ChatUser';
import { UserProfile } from '../Shared/Models/UserProfile';
import { UserProfileContext } from '../Hooks/useProfile';
import jwt from 'jwt-decode';
import { useMsal } from '@azure/msal-react';
import ApiHelper from '../Shared/ApiHelper';
import { APIChatMessage } from '../Shared/Models/ChatTypes';
import { HubConnection } from '@microsoft/signalr';
import { SignalRConnection } from '../Shared/Models/SignalRConnection';
import { SignalRContext } from '../Hooks/UseSignalR';

type ContextType = { 
    ChatUser: ChatUser | undefined,
    Group: ChatGroup | undefined,
    IsReconnecting: boolean,
    Messages: APIChatMessage[],
    SetMessages: React.Dispatch<React.SetStateAction<APIChatMessage[]>>,
    UpdateGroup:  React.Dispatch<React.SetStateAction<ChatGroup>>,
    Connection: HubConnection | undefined
};

export default function ChatPage() {
    const { groupid } = useParams();
    const { instance } = useMsal();

    const userProfile = useContext<UserProfile>(UserProfileContext);
    const signalRConnection = useContext<SignalRConnection>(SignalRContext);

    const [ api ] = useState(new ApiHelper());
    const [ chatUser, setChatUser ] = useState<ChatUser>();
    const [ chatWindowGroup, setChatWindowGroup ] = useState<ChatGroup>({} as ChatGroup);
    const [ isReconnecting ] = useState<boolean>(false);
    const [ chatWindowGroupMessages, setChatWindowGroupMessages ] = useState<APIChatMessage[]>([]);
    const [ timeoutId, setTimeoutId ] = useState<NodeJS.Timeout>();

    const MarkChatMessagesRead = useCallback(() => {
        const patchObj = [{
            op: "replace", 
            path: "/lastRead",
            value: new Date().toISOString()
        }]

        if (groupid && (!signalRConnection.LatestReceivedMessage || signalRConnection.LatestReceivedMessage.group === groupid) && chatUser) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/chatgroup/${groupid}/user/${chatUser.id}`,
                "PATCH",
                JSON.stringify(patchObj),
                new Headers([["Content-Type", "application/json-patch+json"]])
            ).then(res => res.json())
            .then((data: ChatGroup) =>  {
                if (signalRConnection?.ChatGroups && groupid) {
                    signalRConnection.SetChatGroups((prev) => 
                        {
                            const updatedGroups = prev?.map((grp: ChatGroup) => { 
                                if (grp.id === groupid) {
                                    const updatedUser = grp.users.find(x => x.id === chatUser.id);
                                        if (updatedUser) {
                                            updatedUser.lastRead = patchObj[0].value;
                                            return { ...grp, users: [ ...grp.users ] }
                                        }
                                }
                                return grp;
                            });
                            return [...updatedGroups || []];
                        });
                }
            });
        }
    }, [api, chatUser, groupid, instance, signalRConnection]);

    useEffect(() => {
        setChatUser(
            signalRConnection.HubToken
                ? jwt<ChatUser>(signalRConnection.HubToken)
                : undefined
        );
    }, [signalRConnection.HubToken])

    useEffect(() => {
        if (groupid && chatUser) {
            const tid = setTimeout(() => MarkChatMessagesRead(), 3000);

            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setTimeoutId(tid);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(undefined);
            }
        }
    }, [groupid, signalRConnection.LatestReceivedMessage, chatUser]);

    useEffect(() => {
        setChatWindowGroupMessages((prev) => [...prev, signalRConnection.LatestReceivedMessage as APIChatMessage])
    }, [signalRConnection.LatestReceivedMessage])


    const outletContext: ContextType = { 
        Connection: signalRConnection.Connection, 
        ChatUser: chatUser,
        Group: chatWindowGroup,
        UpdateGroup: setChatWindowGroup, 
        Messages: chatWindowGroupMessages, 
        SetMessages: setChatWindowGroupMessages,
        IsReconnecting: isReconnecting
    };

    return (
        <>
            { groupid !== undefined
                ? 
                <Outlet context={outletContext} />
                :
                <div className="content-page-chat default-box d-flex justify-center align-items-center">
                    <div className="container-fluid p-0 d-flex flex-column align-items-center justify-center">
                        <p className="text-center">You are not viewing a conversation.</p>
                        <button className="btn btn-primary default-chat-btn" onClick={e => userProfile?.SetChatNavOpen(true)}>View a conversation</button>
                    </div>
                </div>
            }

        </>
        );
};

export function useChatPageContext() {
    return useOutletContext<ContextType>();
  }