import { useMsal } from "@azure/msal-react";
import { ChangeEvent, FunctionComponent, useState } from "react";
import ApiHelper from "../ApiHelper";
import { CommunicationVerificationProps } from "./CommunicationVerificationProps";
import Loading from "./Loading";

const EmailVerification: FunctionComponent<CommunicationVerificationProps> = ({value, onValueUpdate, isUpdate, onSendCodeSuccess, onSendCodeError, onConfirmCodeSuccess, onConfirmCodeError}) => {
    const [ newValue, setNewValue ] = useState<string>(value);
    const { instance } = useMsal();
    const [ api ] = useState(new ApiHelper());
    const [ codeWasSent, setCodeWasSent ] = useState<boolean>(false);
    const [ code, setCode ] = useState<string>('');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const updateValue = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        setNewValue(value);
        onValueUpdate(value);
    }

    const sendCode = () => {
        const postBody = {
           email: newValue
        }
        setIsLoading(true);
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_CLIENTEX_APIBASE}/user/emailcode`,
            "POST",
            JSON.stringify(postBody)
        ).then(async (result: Response) => {
            if(result.ok) {
                setCodeWasSent(true);
                onSendCodeSuccess();
            } else {
                const body = await result.json();
                setCodeWasSent(false);
                onSendCodeError(body?.errors.join());
            }
        }).catch(async (data: any) => {
            setCodeWasSent(false);
            onSendCodeError(data?.message);
        }).finally(() => setIsLoading(false));
    }
 
    const confirmCode = () => {
        const putBody = {
            email: newValue,
            code
        }
        setIsLoading(true);
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_CLIENTEX_APIBASE}/user/emailcode`,
            "PUT",
            JSON.stringify(putBody)
        ).then(async (result: Response) => {
            const data = await result.json();
            if (data.success) {
                onConfirmCodeSuccess();
            }
            else {
                onConfirmCodeError(data?.message);
            }
        }).catch(err => onConfirmCodeError(err?.message))
        .finally(() => setIsLoading(false));
    }

    return (
        <>
            <input type="text" className="mt-2 text-center form-control" defaultValue={value} disabled={!isUpdate} onChange={updateValue} />
            {!!newValue &&
                <button className="btn-clearall a ml-1 flex-grow-2" onClick={sendCode}>Send Code</button>
            }
            {isLoading ?
                <Loading /> :
                codeWasSent &&
                <>
                    <input type="text" className="mt-2 text-center form-control" onChange={e => setCode(e.target.value)} />
                    <button className="btn btn-primary w-100 mt-2" onClick={confirmCode} disabled={code.length === 0}>Validate</button>
                </>
            }
        </>
    );
}

export default EmailVerification;