import { ChatGroup } from "./ChatGroup"

export enum ChatMessageType {
    User,
    OtherUser,
    System
}

export interface UIChatMessage {
    messageType: ChatMessageType,
    messageContent: string,
    messageDate: string,
    isSending: boolean,
    chatGroup: ChatGroup | undefined,
    showSenderName: boolean,
    sender: string
}

export interface APIChatMessage {
    group: string,
    message: string,
    id: string,
    sender: string,
    time: string,
    broadcastId: string | null
}