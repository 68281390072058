import { Identifier } from "./Identifier";

export interface Registration {
    id: string | undefined,
    name: string,
    emailIdentifier: Identifier | undefined,
    birthdate: Date,
    requestedDate: Date,
    completionStatus: CompletionStatus,
    updatedDate: Date,
    entityId: string,
    createdBy: string,
    associatedProvider: RegistrationProvider,
    verificationProblem: string | undefined
}

export interface RegistrationProvider {
    id: string,
    name: string,
    identifiers: Identifier[]
}

export enum CompletionStatus {
    Pending = 0,
    Verified,
    Declined,
    Failed,
    Completed
}

export interface UpdateRegistrationResponse {
    error: string | undefined
}