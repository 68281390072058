import { EndSessionRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        const logoutRequest: EndSessionRequest = {
            postLogoutRedirectUri: '/'
          };
          instance.logout(logoutRequest);
    }

    return (
        <button className="btn border" onClick={handleLogout} >Sign Out</button>
    )
};