export interface Identifier {
    type?: IdentifierType | undefined,
    value: string,
	verified: boolean | undefined
}

export enum IdentifierType 
{
	Unknown = 0,
	Email = 1,
	Phone = 2,
	Url = 3,
}
