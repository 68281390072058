export enum StatusCode {
    Offline = 0,
    Available = 1,
    BeRightBack = 2,
    Busy = 3,
    DoNotDisturb = 4,
    Message = 200,
    Typing = 201,
    TypingStopped = 202,
    JoinedGroup = 220,
    LeftGroup = 221,
    NotAuthenticated = 250
}