import React, { FunctionComponent } from "react";

interface ChatGroupAcceptanceProps {
    chatUsers: string[],
    onAccept: Function,
    onDecline: Function
}

const ChatGroupAcceptance: FunctionComponent<ChatGroupAcceptanceProps> = ({chatUsers, onAccept, onDecline}) => {
    return (
        <div className='card mx-auto'>
            <div className='card-body'>
                <p>You have been invited to chat with</p>
                <div className='text-center'>
                    <ul className='list-unstyled'>
                    {
                        chatUsers.map((name, i) => (
                            <li key={i}>{name}</li>
                        ))
                    }
                    </ul>
                </div>
                <div className='d-flex justify-content-around'>
                    <button type="button" className="btn btn-primary" onClick={() => onAccept()}>Accept</button>
                    <button type="button" className="btn btn-danger" onClick={() => onDecline()}>Decline</button>
                </div>
            </div>
        </div>
    );
};

export default ChatGroupAcceptance;