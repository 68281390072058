import { createContext, useCallback, useEffect, useState } from "react";

import { SignalRConnection } from '../Shared/Models/SignalRConnection';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from "@microsoft/signalr";
import { Session } from "../Shared/Models/Session";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import ApiHelper from "../Shared/ApiHelper";
import { ChatGroup } from "../Shared/Models/ChatGroup";
import { APIChatMessage } from "../Shared/Models/ChatTypes";

export const SignalRContext = createContext<SignalRConnection>({} as SignalRConnection);

export function useSignalR(): SignalRConnection {
    const { accounts, instance } = useMsal();
    const [ api ] = useState(new ApiHelper());

    const [connection, setConnection] = useState<HubConnection>();
    const [ hubToken, setHubToken ] = useState<string | undefined>();
    const [ session, setSession ] = useState<Session>();
    const [ newGroups, setNewGroups] = useState<ChatGroup[]>([]);
    const [ latestReceivedMessage, setLatestReceivedMessage ] = useState<APIChatMessage>();
    const [ chatGroups, setChatGroups ] = useState<ChatGroup[]>();
    const [ isLoading, setIsLoading] = useState<boolean>(false);

    const iniatializeSignalR = useCallback(() => {
        setIsLoading(true);

        const acct = accounts?.find((a: AccountInfo) => a.idTokenClaims?.aud === process.env.REACT_APP_B2C_CLIENTID);
        if (acct && api && instance) {
            const postobj = JSON.stringify({ email: acct.username });
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/authentication/session`,
                "POST",
                postobj
            )
            .then(res => res.json())
            .then((data: Session) =>  {
                setSession(data);
                return data;
            })
            .then((session) => {
                if(session?.id){
                    const endpoint = `${process.env.REACT_APP_SIGNALR_HUB}/auth/session/${session.id}`;
                    return fetch(endpoint, { method: "POST", body: JSON.stringify(session), headers: { 'Content-Type': 'application/json' }});
                }
                return Promise.resolve(undefined);
            })
            .then(res => res?.json())
            .then((data: {id: string, token: string}) => {
                setHubToken(data.token);
                return data.token;
            })
            .then((token) => {
                const hubOptions: IHttpConnectionOptions = { 
                    accessTokenFactory: function(): string { return token || ''  }
                };
                const hubConnection: HubConnection = new HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_SIGNALR_HUB || '', hubOptions)
                .withAutomaticReconnect()
                .build();

                hubConnection.on('groupUpdate', function(groups: ChatGroup[]) {
                    //Handles the user joining a group
                    setNewGroups(groups);
                });
                
                hubConnection.start()
                    .then(() => {
                        hubConnection?.on("message", (msg) => setLatestReceivedMessage(msg))
                    })
                    .catch((err) => console.error(err))
        
                setConnection(hubConnection);
            })
            .catch((ex) => console.log('There was an issue connecting to chat. Error ' + ex))
            .finally(() => setIsLoading(false));
        }
    }, [accounts, api, instance]);

    useEffect(() => {
        iniatializeSignalR();
    }, [iniatializeSignalR, accounts, api, instance]);

    useEffect(() => {
        setChatGroups((prev) => {
            const previousGroups = prev === undefined ? [] : prev.filter(old => !newGroups?.find(group => group.id === old.id));
            return [...previousGroups, ...newGroups || []]});
    }, [newGroups])

    useEffect(() => {
        if (latestReceivedMessage) {
            setChatGroups((prev) => prev?.map((grp: ChatGroup) => grp.id === latestReceivedMessage?.group 
            ? { ...grp, messages: [...grp.messages, latestReceivedMessage]} 
            : grp));
        }

    }, [latestReceivedMessage])

    return {
        Connection: connection,
        HubToken: hubToken,
        Session: session,
        LatestReceivedMessage: latestReceivedMessage,
        ChatGroups: chatGroups,
        SetChatGroups: setChatGroups,
        IsLoading: isLoading
    };
}