import { FunctionComponent, useEffect, useState } from "react";
import { NotificationPreferences } from "../Shared/Models/NotificationPreferences";

import { Link } from "react-router-dom";

interface ContactInformationCardProps {
    userNotifications: NotificationPreferences
}

const ContactInformationCard: FunctionComponent<ContactInformationCardProps> = ({userNotifications}) => {
    const [emailIsVerified, setEmailVerified] = useState<boolean>(false);

    useEffect(() => {
        let firstEmail = userNotifications?.email?.find(x => x);
        setEmailVerified(firstEmail?.verified || false);
    }, [userNotifications]);

    return (
        <>
            <div className="col-md-6 col-lg-8">
                <div className="card card-block card-stretch card-height">
                    <div className="card-header">
                        <div className="header-title d-flex justify-content-between align-items-center">
                            <h4 className="card-title">Contact Information</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <ul className="list-inline p-0 m-0 iq-contact-rest">
                            {/* <li className="mb-3 d-flex align-items-center">
                                <div>
                                    <span>
                                        <i className="mr-3">
                                            <svg width="20" className="svg-icon" id="up-02" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
                                            </svg>
                                        </i>
                                    </span>
                                </div>
                                <p className="mb-0 font-size-16 line-height">{userNotifications.phone?.find(x => x)?.phone ?? "No Phone Set"}</p>
                                <Link to='/main/user/contact' state={{ type: "phone", notificationPreferences: userNotifications, isUpdate: true }} className="ml-3 btn btn-outline-primary">Update</Link>
                            </li> */}
                            <li className="mb-3 d-flex flex-column">
                                <div className="d-flex align-items-center">
                                    <span>
                                        <i className="mr-3">
                                            <svg width="20" className="svg-icon" id="up-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"></path>
                                            </svg>
                                        </i>
                                    </span>
                                    <p className="mb-0 font-size-16 line-height">
                                        {userNotifications.email?.find(x => x)?.email}
                                        <Link to="/main/user/contact" state={{ type: "email", notificationPreferences: userNotifications, isUpdate: true }} className="ml-3 btn btn-outline-primary">Update</Link>
                                    </p>
                                </div>
                                {
                                    !emailIsVerified &&
                                        <p className="text-warning">
                                            <i className="la la-exclamation-circle"></i>
                                            <span className="m-l-2">You will not receive notification emails until you verify your email.</span>
                                        </p>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactInformationCard;